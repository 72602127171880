.ModalNotification {
  &:hover .ModalNotification__extended {
    display: block;
  }
}

.ModalNotification__extended {
  position: absolute;
  top: 100px;
  left: 25px;
  z-index: 100000000000 !important;
  display: none;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 1px 5px #8f8f8f;
}

.ModalNotification__label {
  border-radius: 50%;
  background-color: rgb(176, 176, 176);
  // padding: auto;
}

.ModalNotification__position {
  top: 0;
  left: 60%;
  width: 200px;
}

.ModalNotification__pos_left {
  top: 0;
  left: -200%;
  width: auto;
}

.ModalNotification__status-position {
  top: 130%;
  left: 85%;
  max-width: 200px;
}

.ModalNotification__button-position {
  top: 130%;
  left: -400%;
  max-width: 200px;
  z-index: 100000000000 !important;
}

.ModalNotification__table-position {
  top: -200%;
  left: -50%;
}

.ModalNotification__receiver-checkbox {
  top: -100px;
  left: -200px;
}

.ModalNotification__track-status {
  top: -60px;
  left: -200px;
}
